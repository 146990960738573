@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/mixins.scss';

.table-body {
  .table-item-value {
    color: variables.$textPassiveState;
    min-width: 61px;
    overflow: hidden;
    white-space: nowrap;
  }

  .track-item {
    width: 250px;
    white-space: nowrap;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      @include mixins.regularIcon();
      margin: 0px 5px;
    }
  }

  .controls-container {
    .add-track {
      @include mixins.regularIcon();
      background-image: url('../../../../../../assets/icons/closestTracksAddPassive2.svg');
      margin-right: 12px;
    }

    span {
      color: variables.$textPassiveState;
    }

    &.disabled {
      @include mixins.disabledControlInline();
    }

    &:hover {
      cursor: pointer;

      .add-track {
        background-image: url('../../../../../../assets/icons/closestTracksAddActive1.svg');
      }

      span {
        color: variables.$textActiveStateWhite;
      }
    }
  }
}
