@use './variables.scss';
@use './mediaqueries.scss';

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: variables.$listItemInFocus;
}
::-webkit-scrollbar-thumb {
  background-color: variables.$textPassiveState;

  &:hover {
    background: #fff;
  }
}

.app-title {
  font-size: 30px;
  font-family: 'Inter';
  font-weight: 400;
  text-decoration: none;
  color: variables.$sidebarBrandText;

  &:hover {
    color: variables.$sidebarBrandText;
  }
}

.visibility-hidden {
  visibility: none;
}

.article-title-lg1 {
  font-family: Inter;
  font-size: 23px;
  letter-spacing: 0;
  line-height: 28px;
}

.article-text-lg2 {
  font-family: Inter;
  font-size: 19px;
  letter-spacing: 0;
  line-height: 28px;
}

.article-text-lg3 {
  font-family: Inter;
  font-size: 33px;
  letter-spacing: 0.25px;
  line-height: 40px;
}

.article-text-lg4 {
  font-family: Inter;
  font-size: 58px;
  letter-spacing: -0.5px;
  line-height: 70px;
}

.section-text-sm1 {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.21px;
  line-height: 20px;
}

.section-text-sm2 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 17px;
}

.section-text-sm3 {
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.14px;
  line-height: 12px;
  text-align: center;
}

.section-text-sm4 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.47px;
  line-height: 20px;
}

.section-text-sm5 {
  font-family: Inter;
  font-size: 19px;
  letter-spacing: 0.15px;
  line-height: 23px;
}

.section-text-sm6 {
  font-family: Inter;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 20px;
  text-align: right;
}

.section-text-sm7 {
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.33px;
  line-height: 20px;
}

.section-text-sm8 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.section-text-sm9 {
  font-family: Inter;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
}
