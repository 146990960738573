@use '../../../../styles/variables.scss';
@use '../../index.scss' as homeStyle;

.instructions-container {
  .instruction-item {
    padding-bottom: 50px;

    .button-container {
      width: auto;

      button {
        margin-top: 50px;
        width: 250px;
      }
    }
  }

  .instruction-item:last-of-type() {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
