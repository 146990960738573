@use '../../../../../../styles/variables.scss';

.instruction-item {
  background: transparent;
  border: 1px solid variables.$lightText;
  border-radius: 10px;
  padding: 20px 50px;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .picture-container {
    margin-bottom: 20px;
  }

  .title {
    color: variables.$isofiBrandHighlight;
  }

  .text-container {
    color: variables.$textPassiveState;
    max-width: 800px;
  }

  .width-playlist-builder {
    img {
      width: 187.83px;
      height: 152px;
    }
  }

  .width-closes-track {
    img {
      width: 169.28px;
      height: 123.23px;
    }
  }

  .width-playlist {
    img {
      width: 195.2px;
      height: 166px;
    }
  }
}
