@use '../../../../styles/variables.scss';
@use '../../../../styles/mixins.scss';

.right-sidebar-container {
  width: variables.$rightSidebarWidth;
  min-width: variables.$rightSidebarWidth;
  margin: 16px 24px;
  border: 1px solid variables.$backgroundBgPrimary;
  border-radius: 10px;

  .top-container {
    background-color: variables.$sideContentBackground;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;

    .title {
      color: variables.$textPassiveState;
      padding-top: 24px;
      padding-bottom: 8px;
    }

    .info {
      color: variables.$textPassiveState;
      span {
        margin: 3px;
      }
    }

    .share-button-container {
      width: 100%;
      padding: 20px 0px;

      button {
        width: 100%;
      }
    }
  }

  .control-buttons-container {
    background: transparent;
    border-top: 1px solid variables.$lightText;
    border-bottom: 1px solid variables.$lightText;
    padding: 11px 0px;

    .control-button {
      color: variables.$textPassiveState;
      margin-left: 24px;
      margin-right: 24px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .instruction-item {
    border-radius: 0px;
    border: none;
    padding: 20px;
    margin-top: 30%;
  }
}
