@use '../../../../styles/variables.scss';

.menu-container {
  position: relative;

  .menu-short {
    min-width: 250px;

    .menu-clickable {
      cursor: pointer;
    }

    img {
      width: 44px;
      height: 44px;
      margin-right: 10px;
      border-radius: 50%;
    }

    span {
      display: inline-block;
      color: variables.$displayName;
      margin-right: 10px;
    }

    .login {
      color: variables.$lightText;
      text-transform: uppercase;

      &:hover {
        cursor: pointer;
      }
    }

    svg {
      color: variables.$textActiveStateWhite;
    }
  }

  .menu-detailed {
    position: absolute;
    width: 100%;
    top: variables.$headerHeight;
    left: 0px;
    background-color: variables.$header;
    border: 1px solid variables.$lightText;

    .menu-option {
      width: 100%;
      padding: 5px 10px;
      text-decoration: none;
      color: variables.$lightText;

      &:nth-of-type(2) {
        border-bottom: 1px solid variables.$lightText;
      }

      &:nth-of-type(5) {
        border-bottom: 1px solid variables.$lightText;
      }

      &:hover {
        cursor: pointer;
        background-color: variables.$lightText;
        color: variables.$header;
      }
    }
  }

  .menu-opened {
    display: flex;
  }

  .menu-closed {
    display: none;
  }
}
