@use '../../../../../../styles/variables.scss';
@use '../../../../../../styles/mixins.scss';

.playlist-tracks-container {
  .playlist-track {
    width: 100%;
    padding: 2px 2px 2px 22px;
    overflow: hidden;

    img {
      @include mixins.mediumIcon();
      margin: 8px 16px;
    }

    .track-info {
      overflow: hidden;
      white-space: nowrap;
      width: 150px;
      .artist-name {
        overflow: hidden;
        text-overflow: ellipsis;
        color: variables.$textActiveStateWhite;
      }

      .track-name {
        overflow: hidden;
        text-overflow: ellipsis;
        color: variables.$textPassiveState;
      }

      span {
        padding: 2px;
      }
    }

    .drag-and-drop-icon-container {
      svg {
        @include mixins.regularIcon();
        color: variables.$textPassiveState;

        &:hover {
          cursor: grab;
          color: variables.$isofiBrandHighlight;
        }
      }
    }

    &.active {
      background-color: variables.$sideContentBackground;
    }

    &.currently-dragging {
      background-color: beige;
    }

    &:hover {
      background-color: variables.$sideContentBackground;
    }
  }
}
